import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'records-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent implements OnInit {

  @Input()
  text: string; 
  @Input()
  title: string; 
  @Input()
  icon: string; 
  @Input()
  img: string; 

  constructor() { }

  ngOnInit(): void {
  }

}
