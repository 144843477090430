import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecordsPricingComponent } from './records-pricing/records-pricing.component';
import { RecordsPropertiesComponent } from './records-properties/records-properties.component';
import { RecordsSecurityComponent } from './records-security/records-security.component';

const routes: Routes = [
  { path: '', component: RecordsPropertiesComponent },
  { path: 'price', component: RecordsPropertiesComponent },
  { path: 'security', component: RecordsPropertiesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
