import { BrowserModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InlineSVGModule } from 'ng-inline-svg';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { RecordsPropertiesComponent } from './records-properties/records-properties.component';
import { RecordsSecurityComponent } from './records-security/records-security.component';
import { RecordsPricingComponent } from './records-pricing/records-pricing.component';
import { BannerComponent } from './banner/banner.component';
import { TextBlockComponent } from './text-block/text-block.component';
import { QuoteComponent } from './quote/quote.component';
import { ImageContentBlockComponent } from './image-content-block/image-content-block.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { PriceCardComponent } from './price-card/price-card.component';
import { InViewDirective } from './in-view.directive';

// import {AppConfiguration} from './config/app-config';
import {JsonAppConfigService} from './config/json-app-config.service';
import { AppConfiguration } from './config/app-config';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

//Loads config
export function initializerFn(jsonAppConfigService: JsonAppConfigService) {
  return () => {
    return jsonAppConfigService.load();
  };
}


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    RecordsPropertiesComponent,
    RecordsSecurityComponent,
    RecordsPricingComponent,
    BannerComponent,
    TextBlockComponent,
    QuoteComponent,
    ImageContentBlockComponent,
    LanguageSelectorComponent,
    PriceCardComponent,
    InViewDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      // defaultLanguage: 'is',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: AppConfiguration,
      deps: [HttpClient],
      useExisting: JsonAppConfigService
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [JsonAppConfigService],
      useFactory: initializerFn
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
