import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfiguration} from './app-config';

@Injectable({
  providedIn: 'root'
})
export class JsonAppConfigService extends AppConfiguration {

  constructor(private http: HttpClient) {
    super();
  }

  // This function needs to return a promise
  load() {
    return this.http.get<AppConfiguration>('../assets/conf/config.json')
      .toPromise()
      .then(data => {
        this.recordsAppUrl = data.recordsAppUrl;
        this.showLoginButton = data.showLoginButton;
      })
      .catch(() => {
        console.error('Could not load configuration');
      });
  }
}