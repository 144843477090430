<h3 class="price__title">{{ title }}</h3>
<div class="price__value" [innerHTML]="price"></div>
<div class="price__description">{{ description }} </div>
<div class="price__info" 
  *ngFor="let item of values">
  <div class="price__info__text">{{ item.LABEL }}</div>
  <div class="price__info__value">{{ item.VALUE }}</div>
</div>


