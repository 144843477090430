import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'records-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: ['./price-card.component.scss'],
  host: {
    '[class.show__description]': 'showDescription'
  },
})
export class PriceCardComponent implements OnInit {
  @Input()
  showDescription: boolean;

  @Input()
  title: string;

  @Input()
  price: string;

  @Input()
  values: any;

  @Input()
  description: string;

  constructor() { }

  ngOnInit(): void {
  }

}
