<records-menu></records-menu>
<div class="content" [class.load]="load">
  <records-properties id="properties" recordsInView></records-properties>
  <!-- <records-security id="security" recordsInView></records-security> -->
  <!-- <records-pricing id="pricing" recordsInView></records-pricing> -->
  
  <div class="footer">
    
    <div class="footer__icons">
      <img src="../assets/rannís.svg" alt="">

      <ng-container *ngIf="'FOOTER.TECH_FUND_URL' | translate as img">
        <img [src]="'../assets/' + img" alt="">
      </ng-container>

      <img [src]="'../assets/' + 'FOOTER.RANNIS_TEXT' | translate" alt="">
    </div>
    
    <div class="footer__text">{{ 'FOOTER.RANNIS_TEXT' | translate }}</div>
  </div>
  
  <div class="records__copyright">{{ 'FOOTER.COPYRIGHT' | translate }}</div>
</div>