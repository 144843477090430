<span class="mobile-logo"></span>
<div class="nav-button" id="hamburger" (click)="toggle()">
  <span></span>
  <span></span>
  <span></span>
</div>

<ul class="nav">
  <li class="nav-item logo"><a href="/" (click)="close()"></a></li>
  <!-- <li class="nav-item"><a href="#properties" (click)="close()">Eiginleikar</a></li>
  <li class="nav-item"><a href="#security" (click)="close()">Öryggi</a></li> -->
  <!-- <li class="nav-item"><a href="#pricing" (click)="close()">Verð</a></li> -->
  <li class="nav-item nav-item--button">
    <button *ngIf="showLogin" (click)="goToRecordsApp()" class="button__primary login icon-login">Innskráning</button>
    <records-language-selector></records-language-selector>
  </li>
</ul>
