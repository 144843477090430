import { Component, OnInit } from '@angular/core';
import { AppConfiguration } from './config/app-config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  load: boolean = true;

  constructor() {  }

  ngOnInit(): void {
    // TODO fix this
    setTimeout(() => {
      this.load = false;
    }, 100);
  }
}
