import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'records-image-content-block',
  templateUrl: './image-content-block.component.html',
  styleUrls: ['./image-content-block.component.scss']
})
export class ImageContentBlockComponent implements OnInit {
  @Input()
  public readonly imgSrc: string;
  @Input()
  public readonly svgSrc: string;
  @Input()
  public readonly imgAlign: "right" | "left";
  
  constructor() { }

  ngOnInit(): void {
  }

}
