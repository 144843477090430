import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'records-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  constructor(public translate: TranslateService) {
    translate.addLangs(['is', 'en']);
    translate.setDefaultLang('is');

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|is/) ? browserLang : 'is');
  }

  ngOnInit(): void {
  }

}
