import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations'; 
import { Component, OnInit } from '@angular/core';

export const fadeInOutAnimation = trigger('fadeInOut', [
  transition(':enter', [   // :enter is alias to 'void => *'
    style({ opacity: 0 }),
    animate(3000, style({ opacity: 1 }))
  ]),
  transition(':leave', [   // :leave is alias to '* => void'
    animate(3000, style({ opacity: 0 }))
  ])
])

@Component({
  selector: 'records-properties',
  templateUrl: './records-properties.component.html',
  styleUrls: ['./records-properties.component.scss'],
  animations: [
    fadeInOutAnimation
  ]
})
export class RecordsPropertiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
