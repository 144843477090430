import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { fromEvent, Observable, Subscription } from "rxjs";
import { AppConfiguration } from '../config/app-config';

@Component({
  selector: 'records-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  isOpen: boolean = false;
  showLogin: boolean = false;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(private appConfig: AppConfiguration) { }

  ngOnInit(): void {
  this.showLogin = this.appConfig.showLoginButton;

  }

  ngOnDestroy() {
  }

  @HostBinding('class.open') get open(){ 
    return this.isOpen;
  };

  toggle(): void {
    this.isOpen = !this.isOpen
  }

  close(): void {
    console.log('YO')
    this.isOpen = false
  }

  goToRecordsApp() {
    window.location.href=this.appConfig.recordsAppUrl;
  }
}
