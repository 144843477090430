import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'records-pricing',
  templateUrl: './records-pricing.component.html',
  styleUrls: ['./records-pricing.component.scss']
})
export class RecordsPricingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
