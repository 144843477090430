import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'records-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input()
  public readonly description: string;
  @Input()
  public readonly title: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
