import { Directive, AfterViewInit, OnDestroy, Output, EventEmitter, ElementRef, Host, Renderer2 } from '@angular/core';

@Directive({
  selector: '[recordsInView]'
})
export class InViewDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange: EventEmitter<string> = new EventEmitter<string>();
  private _observer: IntersectionObserver;
  visibleState = false

  constructor(
    @Host() private _elementRef: ElementRef, 
    private render: Renderer2) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const options = { root: null, rootMargin: "0px", threshold: 0.5 };
      this._observer = new IntersectionObserver(this._callback, options);
      this._observer.observe(this._elementRef.nativeElement);
    }, 100);
  }

  ngOnDestroy() { 
    this._observer.disconnect(); 
  }

  private _callback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this._elementRef.nativeElement.classList.add('visible');
        this.visibleState = true;
      }
    })
  }
}
