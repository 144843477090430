<records-banner 
description="{{ 'PROPERTIES.BANNER.DESCRIPTION' | translate }}" 
title="{{ 'PROPERTIES.BANNER.TITLE' | translate }}">
  <p class="banner__text">{{ 'PROPERTIES.BANNER.TEXT' | translate }}</p>
  <!-- <button class="button__primary">{{ 'PROPERTIES.BANNER.CREATE_ACCOUNT' | translate }}</button> -->
  <!-- <button class="button icon-arrow">{{ 'PROPERTIES.BANNER.MORE' | translate }}</button> -->
</records-banner>

<h2 class="text-block-title">{{ 'PROPERTIES.DESCRIPTION_LIST.TITLE' | translate }}</h2>
<div class="text-block-list">
  <records-text-block *ngFor="let item of 'PROPERTIES.DESCRIPTION_LIST.LIST' | translate | keyvalue"
    title="{{item.value.TITLE}}"
    text="{{item.value.TEXT}}"
    icon="{{item.value.ICON}}"></records-text-block>
</div>


<!-- <records-quote>
  {{ 'PROPERTIES.QUOTE' | translate }}
</records-quote> -->