<div class="pricing__text block-space">
  <h3>{{ 'PRICING.TITLE' | translate }}</h3>
  <p [innerHTML]="'PRICING.TEXT' | translate"></p>
</div>  

<div class="price__wrapper">
  <records-price-card 
    [showDescription]="true"
    *ngFor="let item of 'PRICING.PRICE_GROUPS' | translate | keyvalue; first as isFirst"
    title="{{ item.value.TITLE }}"
    [price]="item.value.PRICE"
    [values]="item.value.LIST"
    description="{{item.value.DESCRIPTION}}"
    [showDescription]="isFirst">
  </records-price-card>
</div>